@use "/src/styles/mixins" as mixins;
@use "/src/styles/variables" as variables;

.footer {
  padding: 80px 0;

  color: #ffffff;
  background-color: variables.$color-blue-darker;

  @media (max-width: variables.$media-tablet-width) {
    padding: 68px 0;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    @media (max-width: variables.$media-desktop-width) {
      flex-direction: column;
    }

    &__left {
      display: flex;
      font-weight: 400;

      @media (max-width: variables.$media-desktop-width) {
        margin-top: 40px;
        flex-direction: column;
      }

      &__small {
        font-weight: 400;
        margin-top: 8px;
        margin-bottom: 8px;

        @media (max-width: variables.$media-desktop-width) {
          margin-top: 4px;
          margin-bottom: 0;
        }
      }

      &__item {
        margin-left: 40px;
        @media (max-width: variables.$media-desktop-width) {
          margin-left: 0;
          margin-top: 24px;
        }
      }
    }

    &__right {
      &__text {
        text-align: right;
        line-height: 30px;
        margin-top: 24px;

        @media (max-width: variables.$media-desktop-width) {
          margin-top: 16px;
          text-align: left;
          justify-content: start;
        }
      }
    }
  }

  &__bottom {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    @media (max-width: variables.$media-desktop-width) {
      flex-direction: column;
    }

    &__left {
      line-height: 26px;
    }

    &__right {
      margin-top: auto;
      margin-bottom: auto;
      &__links {
        display: flex;
        flex-direction: row;
        justify-content: end;

        @media (max-width: variables.$media-desktop-width) {
          margin-top: 40px;
          justify-content: start;
        }
      }
      &__text {
        margin-top: 16px;
      }
    }
  }

  &__links {
    color: inherit;
    text-decoration: none;

    &--regular {
      font-weight: 400;
    }
  }
}

.community {
  &__link {
    margin-left: 24px;
    @media (max-width: variables.$media-desktop-width) {
      margin-left: 0;
      margin-right: 24px;
    }
  }
  &__icon {
    display: block;
    width: 25px;
    height: 25px;
  }
}
