@use "../../variables" as variables;

.warning {
  padding-bottom: 24px;

  @media (max-width: variables.$media-phone-width) {
    padding-bottom: 14px;
  }

  &__content {
    text-align: center;
  }

  &__title {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;

    @media (max-width: variables.$media-phone-width) {
      font-size: 14px;
    }
  }
}
