@use "variables" as variables;
@use "mixins" as mixins;

.container {
  max-width: variables.$container-width;
  margin: auto;

  @media (max-width: variables.$container-width) {
    padding-left: variables.$container-padding;
    padding-right: variables.$container-padding;
  }
}

.icon {
  display: inline-block;
}

.section {
  padding-top: 60px;
  padding-bottom: 60px;

  &__title {
    @include mixins.responsiveProperty(font-size, 32, 24);
    @include mixins.responsiveProperty(line-height, 40, 30);
    font-weight: 700;

    &--color-blue {
      color: variables.$color-blue;
    }

    &--color-white {
      color: #fff;
    }
  }

  &__subtitle {
    @include mixins.responsiveProperty(font-size, 40, 18);
    @include mixins.responsiveProperty(line-height, 49, 22);
    font-weight: 600;
  }
}

.icon-link {
  &__img {}
}

.sm-hide {
  @media (max-width: variables.$media-desktop-width) {
    display: none;
  }
}

.xl-hide {
  @media (min-width: variables.$media-desktop-width + 1) {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.list-item {
  background-color: rgba(2, 22, 57, 0.06);
  border-radius: 90px;

  padding: 24px 70px;
  text-align: center;

  @media (max-width: variables.$media-phone-width) {
    border-radius: 40px;
  }

  &--with-link {
    padding: 0;
  }

  &__link {
    display: block;
    height: 100%;
    padding: 24px 70px;
    text-align: center;
  }
}
