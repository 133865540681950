@use "/src/styles/variables" as variables;
//
//.header {
//  padding-top: 24px;
//  padding-bottom: 24px;
//  background-color: #FFFFFF;
//
//  &__banner {
//  }
//
//  &__burger {
//  }
//
//  &__logo {
//  }
//
//  &__content {
//    position: relative;
//    display: flex;
//    align-items: center;
//    flex-wrap: wrap;
//
//    @media (max-width: variables.$media-header-burger-width), (max-width: variables.$media-header-burger-width) and (-webkit-device-pixel-ratio: 3) {
//      justify-content: space-between;
//    }
//  }
//
//  &__nav {
//    margin-left: auto;
//  }
//}
//
//.trigger {
//  display: none;
//  background: none;
//  width: 50px;
//  height: 48px;
//
//  border-radius: 8px;
//  border: 2px solid #6B7FC8;
//
//  @media (max-width: variables.$media-header-burger-width), (max-width: variables.$media-header-burger-width) and (-webkit-device-pixel-ratio: 3) {
//    display: block;
//  }
//
//  &__line {
//    position: relative;
//    display: block;
//    width: 22px;
//    height: 2px;
//    margin: auto;
//    background-color: #FFFFFF;
//    border-radius: 20px;
//
//    &::before {
//      content: '';
//      position: absolute;
//      top: -6px;
//      left: 0;
//      right: 0;
//      height: 2px;
//      background-color: #FFFFFF;
//      border-radius: 20px;
//    }
//
//    &::after {
//      content: '';
//      position: absolute;
//      bottom: -6px;
//      left: 0;
//      right: 0;
//      height: 2px;
//      background-color: #FFFFFF;
//      border-radius: 20px;
//    }
//  }
//
//  &--active &__icon--closed {
//    display: none;
//  }
//
//  &--active &__icon--opened {
//    display: block;
//  }
//
//  &__icon {
//    display: block;
//    margin: auto;
//
//    &--closed {
//      width: 27px;
//      height: 20px;
//    }
//
//    &--opened {
//      display: none;
//      width: 24px;
//      height: 24px;
//    }
//  }
//}
//
//.nav {
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//  flex-wrap: wrap;
//  background-color: #fff;
//
//  @media (max-width: variables.$media-header-burger-width), (max-width: variables.$media-header-burger-width) and (-webkit-device-pixel-ratio: 3) {
//    display: block;
//    position: absolute;
//    top: 100%;
//    left: 0;
//    right: 0;
//    padding: 40px 40px 0 40px;
//    visibility: hidden;
//    opacity: 0;
//    transition: visibility 0.2s ease-in, opacity 0.2s ease-in;
//
//    &--active {
//      visibility: visible;
//      opacity: 1;
//    }
//  }
//
//  &__menu {
//  }
//}
//
.logo {
  display: inline-block;

  &__img {
    vertical-align: middle;
  }
}


$header-padding-vertical: 48px;


.header {
  padding-top: $header-padding-vertical;
  padding-bottom: $header-padding-vertical;
  position: relative;
  z-index: 999;

  &__logo {
    margin-right: 20px;

    @media (max-width: variables.$media-header-burger-width), (max-width: variables.$media-header-burger-width) and (-webkit-device-pixel-ratio: 3) {
      order: 1;
    }

    @media (max-width: 325px) {
      margin-bottom: 20px;
    }
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: variables.$media-header-burger-width), (max-width: variables.$media-header-burger-width) and (-webkit-device-pixel-ratio: 3) {
      justify-content: space-between;
    }

    @media (max-width: variables.$media-phone-width) {
      align-items: flex-start;
    }
  }

  &__burger {
    @media (max-width: variables.$media-header-burger-width), (max-width: variables.$media-header-burger-width) and (-webkit-device-pixel-ratio: 3) {
      order: 3;
      margin-left: auto;
    }
  }

  &__nav {
    margin-left: auto;
  }

}

.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: variables.$media-header-burger-width), (max-width: variables.$media-header-burger-width) and (-webkit-device-pixel-ratio: 3) {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 40px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s ease-in, opacity 0.2s ease-in;

    &--active {
      visibility: visible;
      opacity: 1;

      background: radial-gradient(471.36% 278.64% at -61.33% 214.31%, #7b4cfd 0%, #7b4cfd00 73.85%), linear-gradient(274deg, #021639 6.68%, #081b40 19.22%, #0d1f44 31.75%, #12244a 44.29%, #16284e 56.83%, #1c2d55 69.36%, #203059 81.9%, #23335c 94.44%, #283761 106.97%, #2f3d69 119.51%, #35436f 132.05%, #424e7d 144.58%);

      background-color: #081b40;
      border-radius: 20px;
    }
  }

  &__menu {
  }

  &__contact-link {
    display: inline-block;
    margin-left: 90px;
    padding: 12px 16px;

    border: 1px solid #6B7FC8;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.12);

    color: #F5F4FA;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;

    @media (max-width: variables.$media-header-burger-width), (max-width: variables.$media-header-burger-width) and (-webkit-device-pixel-ratio: 3) {
      margin: 30px 0;
    }
  }
}


.header-nav-menu {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin: 0;
  list-style-type: none;

  @media (max-width: variables.$media-header-burger-width), (max-width: variables.$media-header-burger-width) and (-webkit-device-pixel-ratio: 3) {
    flex-direction: column;
  }

  &__item {
    position: relative;
    margin-left: 44px;

    @media (max-width: variables.$media-header-burger-width), (max-width: variables.$media-header-burger-width) and (-webkit-device-pixel-ratio: 3) {
      margin-left: 0px;

      &:not(:last-child) {
        margin-bottom: 40px;
      }

      &::after {
        display: none;
      }
    }

    &::after {
      content: '';
      width: 4px;
      height: 4px;
      position: absolute;
      top: calc(50% - 2px);
      right: -20px;
      background-color: #F5F4FA;
      border-radius: 50%;
    }

    &:last-child {
      overflow: hidden;
    }
  }

  &__item--last {
    flex-grow: 1;
  }

  &__item-link {
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: #F5F4FA;
    letter-spacing: 0.15px;
  }

}

.header-trigger {
  display: none;
  background: none;
  width: 50px;
  height: 48px;
  -webkit-appearance: none; /* 2 */
  cursor: pointer; /* 3 */
  font-size: 0;
  -webkit-border-fit: border !important;

  border-radius: 8px;
  border: 2px solid #6B7FC8;

  @media (max-width: variables.$media-header-burger-width), (max-width: variables.$media-header-burger-width) and (-webkit-device-pixel-ratio: 3) {
    display: block;
  }

  &__line {
    position: relative;
    display: block;
    width: 22px;
    height: 2px;
    margin: auto;
    background-color: #FFFFFF;
    border-radius: 20px;
    transition: background-color 0.3s ease;

    &::before, &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #FFFFFF;
      border-radius: 20px;
    }

    &::before {
      top: -6px;
      transition: transform 0.3s ease, top 0.3s ease;
    }

    &::after {
      bottom: -6px;
      transition: transform 0.3s ease, bottom 0.3s ease;
    }
  }

  &--active &__line {
    background-color: transparent;

    &::before {
      top: 0;
      transform: rotate(45deg);
    }

    &::after {
      bottom: 0;
      transform: rotate(-45deg);
    }
  }
}
