@use "/src/styles/mixins" as mixins;
@use "/src/styles/variables" as variables;
@use "sass:math" as math;

.main-footer {
  padding-top: 82px;
  padding-bottom: 69px;
  background-color: #03173A;
  color: #FFF;

  &__content {
    max-width: 1228px;
    margin: auto;
    padding: 0 50px;
  }

  &__top {
  }

  &__divider {
    max-width: 1318px;
    height: 1px;
    margin: 11px auto 26px;

    background-color: rgba(255, 255, 255, 0.50);
  }

  &__bottom {
  }
}

.main-footer-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__left {
    margin-right: 30px;
    margin-bottom: 25px;

    @media (max-width: variables.$media-tablet-width) {
      margin-bottom: 47px;
    }
  }

  &__right {
    flex-grow: 1;
    max-width: 677px;
    flex-basis: math.div(677, (1318 - 148)) * 100%;
  }
}

.main-footer-top-left {
  &__title {
    margin-bottom: 30px;

    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    letter-spacing: 1.3px;
    text-transform: uppercase;

    &--highlighted {
      font-weight: 700;
      color: inherit;
    }
  }

  &__address {
    margin-bottom: 36px;

    color: #FFF;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
  }

  &__logo {
    margin-bottom: 31px;
  }

  &__links {
    color: inherit;
    text-decoration: none;

  }
}

.main-footer-top-right {
  &__menu {
    list-style: none;
    margin: 0;
    padding: 0;

    max-width: 652px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__menu-item {
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 25px;
    //&:not(:last-child) {
      margin-right: 33px;
    //}

    @media (max-width: variables.$media-phone-width) {
      flex-basis: calc(50% - 33px);
    }
  }

  &__menu-item-link {
    text-decoration: none;
    color: #F5F4FA;
  }

  &__submenu {
    list-style: none;
    margin: 17px 0 0;
    padding: 0;
  }

  &__submenu-item {
    margin-bottom: 8px;

    font-weight: 300;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  &__contact {
    margin-bottom: 59px;
  }
}

.main-footer-bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;

  &__trademark {
    margin-right: 20px;
    margin-bottom: 26px;
  }

  &__policies {
    margin-left: auto;

    @media (max-width: variables.$media-desktop-width) {
      margin-left: 0;
      margin-right: 24px;
    }
  }
}

.main-footer-community {
  display: flex;
  flex-wrap: wrap;

  &__link {
    margin-right: 24px;
    margin-bottom: 24px;

    @media (max-width: variables.$media-desktop-width) {
      margin-left: 0;
      margin-right: 24px;
    }
  }

  &__icon {
    opacity: 0.5;
    display: block;
    width: 25px;
    height: 25px;
  }
}

.trademark {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__text {
    color: #FFF;
    font-size: 16px;
    font-weight: 300;
    line-height: 35px;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}


.policies {
  &__link {
    color: #FFF;
    text-decoration: none;
    font-size: 16px;
    font-weight: 300;
    line-height: 35px;
    display: block;

    @media (min-width: 500px) {
      display: inline-block;
      &:not(&:last-child) {
        &::after {
          content: ' |';
          display: inline-block;
          margin: 0 5px;
        }
      }
    }
  }
}
